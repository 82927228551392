import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Header } from './header';
import { useCart, useAccount } from '../../provider/frontastic';
import Breadcrumb from '@/components/navigation/breadcrumb';

export interface HeaderSimpleWithLinksWrappedProps {
  data;
}

export const HeaderMegaMenuProductImagesAndFilter: FC<HeaderSimpleWithLinksWrappedProps> = (props) => {
  const {
    addItem,
    updateItem,
    removeItem,
    data = {},
    redeemDiscountCode,
    removeDiscountCode,
    clearCartAndSession,
  } = useCart();
  const router = useRouter();
  const [menuSelected, setMenuSelected] = useState('');

  useEffect(() => {
    if (typeof window !== undefined) {
      const menu = sessionStorage.getItem('menuSelected') || '';
      setMenuSelected(menu);
    }
  }, []);

  const pagesWithoutBreadcrumbs = router.asPath?.match(
    /^\/(|cart|checkout|my-account|login|forgot-password|thank-you|order)(\/.*)?(\?.*)?$/,
  );

  const { login } = useAccount();
  const [showBreadcrumb, setShowBreadcrumb] = useState(true);

  useEffect(() => {
    pagesWithoutBreadcrumbs && setShowBreadcrumb(pagesWithoutBreadcrumbs.length === 0);
  }, [pagesWithoutBreadcrumbs]);

  const logo = props.data.logo;
  const links = props.data.links;
  const panels = props.data.panels;

  const extractMediaFromChildren = (child) => {
    return {
      ...child,
      media: child.media.media,
    };
  };

  const parsePanel = (panel) => {
    return {
      ...panel,
      featuredMenu: {
        ...panel.featuredMenu[0],
        children: panel.featuredMenu[0].children.map(extractMediaFromChildren),
      },
    };
  };

  const args: any = {
    data: {
      currencies: ['USD'],
      calloutMenu: props.data.calloutMenu ? props.data.calloutMenu[0] : null,
      headerMenu: {
        logo: logo.media,
        links,
        panels: panels.map(parsePanel),
        homepageUrl: process.env.NEXT_PUBLIC_SITE_URL,
      },
      useCart: {
        addItem,
        removeItem,
        updateItem,
        redeemDiscountCode,
        removeDiscountCode,
        clearCartAndSession,
        //removeMultipleItems,
        data,
      },
      useAccount: {
        login,
      },
    },
  };
  return (
    <div className="bg-white">
      <Header {...args} />
      {showBreadcrumb && menuSelected !== '' ? (
        <Breadcrumb
          homeElement={'Home'}
          separator={<span> / </span>}
          activeClasses="text-[#B7002A] hover:no-underline"
          containerClasses="flex items-center h-[40px] pl-8"
          listClasses="hover:underline mx-1 font-[Galano Classic] text-[12px]"
        />
      ) : null}
    </div>
  );
};
