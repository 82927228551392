import { useState, useEffect } from 'react';

const getOrientation = () => {
  if (typeof window !== 'undefined' && window.screen?.orientation) {
    return window.screen.orientation.type;
  }
  // Fallback for unsupported browsers
  if (typeof window !== 'undefined' && window.matchMedia) {
    if (window.matchMedia('(orientation: portrait)').matches) {
      return 'portrait';
    }
    if (window.matchMedia('(orientation: landscape)').matches) {
      return 'landscape';
    }
  }
  return 'unknown';
};

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(() => getOrientation());

  useEffect(() => {
    const updateOrientation = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener('orientationchange', updateOrientation);

    // Initial orientation update in case of discrepancies
    updateOrientation();

    return () => {
      window.removeEventListener('orientationchange', updateOrientation);
    };
  }, []);

  return orientation;
};

export default useScreenOrientation;
