import React, { ReactNode, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

function replaceDashesWithSpaces(inputString: string): string {
  return inputString
    .split('-')
    .filter(Boolean)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function toLowerCaseWithDashes(inputString: string): string {
  return inputString
    .split(' ')
    .filter(Boolean)
    .map((word) => word.toLowerCase())
    .join('-');
}

function getPathSegments(asPath: string): string[] {
  return asPath.split('?')[0].split('#')[0].split('/').filter(Boolean);
}

const getPaths = (pathNames: string[]): string[] => {
  if (pathNames.includes('category')) {
    return pathNames.slice(1);
  } else if (pathNames.includes('catalog')) {
    return pathNames.slice(0, -1);
  } else {
    return pathNames;
  }
};

type BreadCrumbProps = {
  homeElement: ReactNode;
  separator: ReactNode;
  containerClasses?: string;
  listClasses?: string;
  activeClasses?: string;
};

const Breadcrumb = ({ homeElement, separator, containerClasses, listClasses, activeClasses }: BreadCrumbProps) => {
  const { asPath } = useRouter();
  const pathNames = useMemo(() => getPathSegments(asPath), [asPath]);
  const paths = useMemo(() => getPaths(pathNames), [pathNames]);
  const productPath = useMemo(() => (pathNames.includes('p') ? pathNames.slice(1) : pathNames), [pathNames]);
  const menuSelected = useMemo(() => sessionStorage.getItem('menuSelected') ?? '', []);
  const isProduct = useMemo(() => pathNames.includes('p'), [pathNames]);

  if (pathNames.length === 0) return null;

  const getHref = (path: string): string => {
    return isProduct ? `/p/${path}` : `${pathNames.includes('category') ? '/category' : ''}/${path}`;
  };

  return (
    <div>
      <ul className={containerClasses}>
        <li className={listClasses}>
          <Link href="/">{homeElement}</Link>
        </li>
        {paths.length > 0 && separator}
        {paths.map((elem, index) => {
          const path = isProduct ? productPath[0] : paths.slice(0, index + 1).join('/');
          const href = getHref(path);
          const isLastPath = paths.length === index + 1;
          const itemClasses = `${listClasses} ${isLastPath ? activeClasses : ''}`;
          const itemLink = replaceDashesWithSpaces(elem === 'p' ? menuSelected : elem);

          return (
            <React.Fragment key={index}>
              <li className={itemClasses}>
                {isLastPath ? (
                  itemLink
                ) : (
                  <Link href={elem === 'p' ? `/category/${toLowerCaseWithDashes(menuSelected)}` : href}>
                    {itemLink}
                  </Link>
                )}
              </li>
              {paths.length !== index + 1 && separator}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumb;
