// @ts-nocheck

import { FC, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useAsio } from '@ariessolutionsio/providers-richproducts';
import { Variant } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Variant';
import useSWR from 'swr';
import Image from 'frontastic/lib/image';
import { useBlobStorage, useCart } from 'frontastic/provider';

const TinStepper = dynamic(
  () =>
    import('@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/hero-sections/TinStepper'),
  { ssr: false },
);

interface CustomTinProps {
  data: {
    data: {
      dataSource: {
        items: {
          variants: Variant[];
        };
      };
    };
  };
}

export const CustomTin: FC<CustomTinProps> = ({ data: componentData }) => {
  const [{ products }] = useAsio();
  const router = useRouter();
  const { data, error } = useSWR('products', () => products.mergeProductVariantsIntoOneProduct());
  const isLoading = !error && !data;

  const [variants, setVariants] = useState<Variant[]>();

  const tinSizes = componentData?.provinces?.dataSource?.value ?? undefined;

  useEffect(() => {
    if (data && !error) {
      const newVariants = data.variants.map((v) => {
        const images = v.images.map((image) => image.url);
        return { ...v, sku: v.sku ?? '', images };
      });
      setVariants(newVariants);
    }
  }, [data]);

  if (error) {
    console.error('products.mergeProductVariantsIntoOneProduct:', error);
    router.push('/custom-tin-loader');
    return <></>;
  }

  return !isLoading ? (
    <TinStepper
      variants={variants}
      useCart={useCart as any}
      useBlobStorage={useBlobStorage}
      Image={Image}
      tinSizes={tinSizes}
    />
  ) : (
    <></>
  );
};
